
import { Clone, useGLTF} from '@react-three/drei'
function CandyCane() {
    let model = useGLTF('./candyCane.glb')
    return (
        <>
            <Clone object={model.scene} />

            </>
    )
}

export default CandyCane
