import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'



const root = ReactDOM.createRoot(document.querySelector('#root'))

const audio = {
    position: 'absolute',
    zIndex: '11'
}

root.render(
    <>
        <audio controls autoPlay style={audio}>
            <source src="./music.mp3" />
            your browser does not support audio. Try the experience on a different device.
        </audio>
    <Canvas
        shadows
        camera={ {
            fov: 45,
            near: 0.1,
            far: 200,
            position: [ 8,2,-2 ]
        } }
    >
        <Experience />
        </Canvas>
        </>
)