import { Center, OrbitControls, Sky, Sparkles, Text3D, PositionalAudio } from '@react-three/drei'
import { useFrame, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {useRef, Suspense, lazy} from 'react'
import {useControls} from 'leva'
import * as THREE from 'three'
import CandyCane from './candyCane'
import { Bloom, EffectComposer } from '@react-three/postprocessing'


export default function Experience()
{
    const tempArray = [...Array(100)]

    const tree = useRef();
    const camera = useRef();
    const presentsRef = useRef();
   
    const musicRef = useRef();
    const candyCaneGroup = useRef();

    useFrame((state, delta) => {
        const camera = state.camera
        const angle = (state.clock.elapsedTime)
        // camera.position.x = Math.sin() * 8
        // camera.position.y = Math.cos() * 8
        // camera.lookAt(tree) 
        // camera.position.x = (Math.sin(angle) * 8) 
        // camera.position.z = (Math.cos(angle) * 8) 
        for (const candy of candyCaneGroup.current.children) {
            candy.rotation.y += delta * 1
        }

    }, [])


    const model = useLoader(GLTFLoader, './Tree.glb')
    const presents = useLoader(GLTFLoader, './Presents.glb')

    return <>
        <EffectComposer >
            <Bloom/>
        </EffectComposer>

        <OrbitControls makeDefault autoRotate={true} />
        <directionalLight castShadow position={ [ 1, 2, -3 ] } intensity={ 1.5 } />
        <ambientLight intensity={ 0.5 } />
        <directionalLight castShadow position={ [ 1, 0, 3 ] } intensity={ .75 } />
       <Center position={[-20, 4 , 5]} rotation={[0, 2.3, 0]} >
            <Text3D font="./montserrat.json"
                size={0.75}
                height={0.2}
                curveSegments={12}
            >
                Merry 
            <meshNormalMaterial />
                </Text3D>
            <Text3D font="./montserrat.json"
                size={0.75}
                height={0.2}
                curveSegments={12}
                position={[0, -1, 0]}
            >
                Christmas!
            <meshNormalMaterial />
                 
            </Text3D>

            
        </Center>
        <Center position={[-25,1,-1]} rotation={[0,1.5,0]}>
            <Text3D font="./montserrat.json">
                From, Jake
            <meshNormalMaterial color='red' />
                 
            </Text3D>
        </Center>

        <mesh receiveShadow position-y={ -1 } rotation-x={ - Math.PI * 0.5 } scale={ 100 }>
            <planeGeometry />
            <meshStandardMaterial color="greenyellow" />
        </mesh>
        <Suspense useLoader={lazy}>
            <mesh >
                
                <primitive ref={tree} object={model.scene} scale={0.35} position-y={-1} />
                <meshStandardMaterial toneMapped={false}/>
                
            </mesh>

            
            </Suspense>

        <Sparkles
            count={1000}
            opacity={0.1}
            color='white'
            size={50}
            scale={50}
            speed={5}
            color="rgb(255,255,255)"
            toneMapped={true}
        >
            <meshBasicMaterial  />
            </Sparkles>
        <group>
            <primitive ref={presentsRef} object={presents.scene} position={[1, -1, 0]} scale={0.25} />
            
        </group>
        <Sky distance={450000} sunPosition={[0, -1, 0]} inclination={0} azimuth={0.01} />
        <Suspense>
            <group ref={candyCaneGroup}>
            {[...Array(100)].map((value, index) =>
                <mesh
                    key={index}
                    position={[
                    (Math.random() - 0.5) * 100,
                    ((Math.random() - 0.5) * 10)+2,
                    (Math.random() - 0.5) * 100
                    
                ]}
                    
                    scale={0.2 + Math.random() * 0.2}

                    rotation={[
                        Math.random() * Math.PI,
                        Math.random() * Math.PI,
                        0
                    ]}
                >
                    <CandyCane />
                    <meshNormalMaterial />
                    </mesh>
                    
                    
                )}
                </group>

            </Suspense>

    </>
}